body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.navbar {
  padding-right: 1rem;
  padding-left: 1rem;
  padding-top: 0;
}

.navbar-brand {
  z-index: 1031;
  background-color: #f8f9fa;
  border-radius: 0 0 30px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main > .container-fluid {
  padding: 50px 60px 0 60px;
}

.card-button button:not(:first-child) {
  margin-left: 5px;
}

.success-check {
  position: absolute;
  right: 10px;
  top: 30%;
}

.test-survey {
  background-color: white;
  padding: 5px;
  position: absolute;
  top: 10px;
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
}

.test-survey > div {
  white-space: nowrap;
}

/* ---- Grid ---- */
.jtn-grid {
  margin-bottom: 10px;
}

.jtn-grid .card, .jtn-grid .card-header {
  background-color: transparent;
  border: none;
}

.jtn-grid .card-header {
  padding: 0px;
}

.jtn-grid .card-header h5 {
  border-bottom: 1px solid black;
  padding-bottom: 5px;
  margin-bottom: 0px;
}

.jtn-grid .card-body {
  padding: 0px;
}

.jtn-grid .grid-rows {
  position: relative;
}

.jtn-grid .grid-row.loading {
  opacity: 0.1;
}

.jtn-grid .loader {
  background: rgba(0,0,0,0.1);
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
  width:100%;
  height:100%;
  transition:opacity 0.5s ease;
  z-index: -1;
}

.jtn-grid .grid-rows .loader.loading {
  opacity: 1;
  z-index: initial;
}

.jtn-grid .grid-row {
  width: 100%;
}

.grid-row .grid-cell {
  flex-grow: 1;
  -webkit-flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 8px;
}

.jtn-grid .grid-row.header {
  color: #c1c1c1;
  text-transform: uppercase;
  font-weight: normal;
  border-color: transparent;
}

.jtn-grid .grid-row.content {
  background-color: white;
  margin-bottom: 5px;
  min-height: 42px;
  border: 1px solid #d5d5d5;
  padding-right: 2px;
  padding-left: 2px;
}

.jtn-grid .grid-noresult {
  margin-bottom: 5px;
}

.jtn-grid .grid-row.content.threshold .grid-cell {
  background-color: #DFD15A;
}

.jtn-grid .grid-row.threshold {
  border: 1px solid #DFD15A;
}

.jtn-grid .grid-row.paused {
  border: 1px solid red;
}

.jtn-grid .grid-row.finalized-invoice {
  border: 1px solid #28a745;
}

.jtn-grid .grid-button {
  box-shadow: none !important;
  cursor: pointer;
  border: 0px;
  width: 45px;
}

.jtn-grid .page-link {
  color: black;
  cursor: pointer;
}

.jtn-grid .grid-row-cell {
  background-color: white;
}

.jtn-grid .grid-rows .grid-row.flex-column {
  margin-bottom: 5px;
}

.jtn-grid .grid-row-cell button {
  background-color: white !important;
  padding: 5px;
}

.jtn-grid .grid-row-cell .grid-cell {
  padding: 0px;
  margin-left: 0px;
}

.jtn-grid .threshold .mobile-cell {
  background-color: #DFD15A;
}

.jtn-grid .button-cell {
  padding-left: 0px;
}

.jtn-grid .grid-row.content .button-cell {
  background-color: transparent !important;
}

.jtn-grid .mobile-cell {
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.jtn-grid .gray {
  font-size: 14px;
  color: gray;
}

.jtn-grid .name {
  font-weight: bold;
}

.pointer-cursor, .row-selectable .content {
  cursor: pointer;
}

.jtn-grid .grid-sum-row {
  cursor: default;
  font-weight: bold;
  background-color: #133A57 !important;
  color: white !important;
}

.Toastify__toast-container {
  width: 620px;
}

.Toastify__toast-container--top-center {
  margin-left: -310px;
}

.Toastify__toast {
  border-radius: .25rem;
  min-height: initial;
  padding: .75rem 1.25rem;
}

.Toastify__toast--error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.Toastify__toast--info {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.welcome-card-img {
  width: 40%;
  margin: 10px;
}

.welcome-card-row .card {
  align-items: center;
}

.welcome-card-row .card-body {
  padding: 5px;
}

.status-test {
  color: #4b41dc;
}

.status-live {
  color: #28a746;
}

.status-closed {
  color: #d7373c;
}

.status-paused {
  color: #f5a523;
}

.pagebreak {
  page-break-before: always;
  display: block;
}

pre {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  text-align: justify;
}